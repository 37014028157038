.modal-container .ant-modal-content {
  width: 558px;
  height: auto;
  background-color: #1F242F ;
  border-radius: 17px;
  position: relative;
  opacity: 1;
  padding-bottom: 20px;
}
.modal-container .ant-modal-header {
  border-radius: 17px;
  border: none;

  h1 {
    text-align: left;
    font: normal normal bold 21px/25px Roboto;
    letter-spacing: 0.21px;
    color: #1b1b1b;
    opacity: 1;
    margin-bottom: 5px;
  }
  h2 {
    font-weight: lighter;
    text-align: left;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0.14px;
    color: #1b1b1b;
    opacity: 1;
  }
}
.modal-select .ant-select-arrow {
  color: #1b1b1b;
}
.modal-merchant-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid #d3cbcb;
  padding-bottom: 15px;
  .modal-merchant-left {
    display: flex;
    flex-direction: column;
    width: 15%;
    align-items: center;
    // background-color: aqua;
  }
  .modal-merchant-mid {
    width: 70%;
  }
  .modal-merchant-right {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background: chocolate;
  }
}
.ant-radio-checked .ant-radio-inner {
  border-color: #e31837 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #e31837;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #e31837;
}
.modal-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-button-confirm {
    height: 55px;
    width: 30%;
    background-color: #FF9D02;
    border-radius: 11px;
    letter-spacing: 0.14px;
    color: #1B1B1B;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal bold 14px/21px SVN-Merge;
    border: 0;
    &:hover,
    &:active,
    &:focus {
   
      outline: 0;
    }
  }

  .modal-text-confirm {
    text-align: center;
    font: normal normal bold 16px/24px SVN-Merge;
    letter-spacing: 0.16px;
    color: #1B1B1B;
    opacity: 1;
    font-weight: 700;
  }
  .modal-button-back {
    background: var(--unnamed-color-e31837) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    opacity: 1;
    width: 100%;
    height: 58px;
  }
  .modal-text-back {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font: normal normal bold 16px/24px SVN-Merge;
    letter-spacing: 0.16px;
    color: #e31837;
    opacity: 1;
  }
}
.modal-reject-reason {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 15px;
}
.modal-reject-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid #d3cbcb;
  padding-bottom: 15px;
}
.modal-reject-text {
  width: 85%;
}
.modal-reject-radio {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // background: chocolate;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none !important;
}

.ant-modal-confirm-info{
  top:45% !important;
  .ant-modal-confirm-btns{
    button{
      background-color: #ffffff;
      border:none;
      box-shadow: none;
      color:#1b1b1b;
    }
  }
}
.modal-logout{
  .ant-modal-footer{
    border: none;
  }
}

.modal-warning-complete{
  .ant-modal-content {
    border-radius: 17px !important;
    height: auto !important;
  }

  .ant-modal-footer{
    padding-bottom: 16px !important;
  }
}

.modal-container .ant-modal-header{
 background: transparent !important;
}