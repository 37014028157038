
.popup-orderDetail{
    .ant-modal-content {
        background-color: #1F242F;
        height: auto !important;
        padding-bottom: 0px !important;
      }
}
.popup-orderDetail-light{
    .ant-modal-content {
        background-color: #fff;
        height: auto !important;
        padding-bottom: 0px !important;
      }
}

.orderDetail {
    padding: 1rem;
    padding-top: 2rem;
    font-family: "Montserrat", sans-serif;
    h2, h4, p {
      color: white;
    }
    h2{
        font-size: 24px;
        font-weight: 700;
    }
    p{
        font-size: 12px;
    }
    
    .orderDetail_line{
        width: 100%;
        height: 1px;
        background-color: #EDEDED;
        margin: 16px 0px;
        opacity: 0.3;
    }
    .item_title{
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 12px;
        height: 40px;
    }
    .item{
        margin-top: 16px;
        color: #F5F5F5;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        
    }
    .line{
        width: 100%;
        height: 1px;
        background-color: #EDEDED;
        margin: 8px 0px;
        opacity: 0.3;
    }
  }

.col-w-100{
    width: 120px;
}
.col-item{
    flex: 1;
}

.orderDetailHistory_bottom{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    p{
        font-size: 24px;
        font-weight: 600;
        color: #F5F5F5;
    }
}
.orderDetail_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}